import {gsap} from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import {closeAllModals} from "wildlabs-frontend-core/js/modal.js";

gsap.registerPlugin(ScrollToPlugin);

function getSamePageAnchor(link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname.replace(/^\/|\/$/g, "") !==
    window.location.pathname.replace(/^\/|\/$/g, "") ||
    link.search !== window.location.search
  ) {
    return false;
  }

  return link.hash;
}

export function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : document.documentElement;
  if (elem) {
    if (e) {
      e.preventDefault();
    }
    closeAllModals();
    gsap.to(window, {scrollTo: {y: hash}});
  }
}

export function initScrollLinks() {
  document.querySelectorAll("a[href*='#']").forEach((a) => {
    a.addEventListener("click", (e) => {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
}
