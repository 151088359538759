window.WL = window.WL || {};
window.WL.app = window.WL.app || {};
window.WL.app.sections = window.WL.app.sections || {};

window.WL.app.sections.registered = window.WL.app.sections.registered || {};
window.WL.app.sections.instances = window.WL.app.sections.instances || [];

export let registered = window.WL.app.sections.registered;
export let instances = window.WL.app.sections.instances;

export const SECTION_SELECTOR = "[data-section-component]";

export function register(type, ComponentClass) {
  if (!registered[type]) {
    registered[type] = ComponentClass;
  }
}

function uniqueId() {
  return Math.floor(Math.random() * Date.now());
}

export function load(type, el) {
  if (!registered[type]) {
    console.warn(`The component ${type} doesn't have a script file`);
    return;
  }

  // do not load section twice
  if (loaded(type, el)) {
    return;
  }

  el.dataset.uid = `${uniqueId()}`;
  instances.push({
    uid: el.dataset.uid,
    type,
    instance: new registered[type](el),
  });
}

export function unload(type, el) {
  if (!el.dataset.uid) {
    return;
  }

  const index = instances.map((e) => e.uid).indexOf(el.dataset.uid);

  if (index > -1) {
    instances[index].instance.onUnload();
    instances.splice(index, 1);
  }
}

export function loaded(type, el) {
  if (!el.dataset.uid) {
    return false;
  }

  const index = instances.map((e) => e.uid).indexOf(el.dataset.uid);

  return index > -1;
}

export function loadAll(container, selector = SECTION_SELECTOR) {
  container.querySelectorAll(selector).forEach((section) => {
    load(section.dataset.sectionComponent, section);
  });
}

export function unloadAll(container, selector = SECTION_SELECTOR) {
  container.querySelectorAll(selector).forEach((section) => {
    unload(section.dataset.sectionComponent, section);
  });
}
