import { getStyleVariable } from './styles.js';
import { gsap } from 'gsap';

const selectors = {
  isClosed: 'is-closed',
  isOpen: 'is-open',
  dataAttr: '[data-accordion]',
};

let animation = null;

const onAnimationFinish = (detailsEl, open) => {
  detailsEl.open = open;
  animation = null;
  detailsEl.style.height = '';
};

const onOpenAnimationFinish = (detailsEl, open) => {
  onAnimationFinish(detailsEl, open);

  // if(!detailsEl?.dataset?.noSpringBack) {
  //   gsap.to(window, 0.4, { scrollTo: { y: detailsEl, offsetY: 120 } });
  // }
};

const toggleActiveClass = details => {
  details.classList.toggle(selectors.isOpen, !details.open);
  details.classList.toggle(selectors.isClosed, details.open);
};

const toggleAccordion = (summary) => {
  const
    accordionEl = summary.closest(selectors.dataAttr),
    detailsEl = summary.closest('details'),
    contentEl = summary.nextElementSibling,
    [easingOnClose, durationOnClose, easingOnOpen, durationOnOpen] = [
      accordionEl ? getStyleVariable(accordionEl, '--accordion-easing-on-close') : 'ease-in-out',
      accordionEl ? getStyleVariable(accordionEl, '--accordion-duration-on-close') : 200,
      accordionEl ? getStyleVariable(accordionEl, '--accordion-easing-on-open') : 'ease-in-out',
      accordionEl ? getStyleVariable(accordionEl, '--accordion-duration-on-open') : 300,
    ],
    event = new CustomEvent(detailsEl.open ? 'accordion:close' : 'accordion:open', {
      cancelable: true,
      bubbles: true,
    });

  if (detailsEl.dispatchEvent(event)) {
    toggleActiveClass(detailsEl);

    if (accordionEl && accordionEl.dataset.accordion === 'collapse') {
      const
        openedDetails = accordionEl.querySelector(':scope > [open]'),
        event = new CustomEvent('accordion:close', {
          cancelable: true,
          bubbles: true,
        });

      if (openedDetails && openedDetails !== detailsEl) {
        if (openedDetails.dispatchEvent(event)) {
          toggleActiveClass(openedDetails);
          openedDetails.animate({
            height: [`${openedDetails.offsetHeight}px`, `${openedDetails.firstElementChild.offsetHeight}px`],
          }, {
            duration: Number(durationOnClose),
            easing: easingOnClose,
          }).onfinish = () => openedDetails.open = false;
        }
      }
    }

    if (!animation) {
      if (!detailsEl.open) {
        detailsEl.style.height = `${detailsEl.offsetHeight}px`;
        detailsEl.open = true;
        animation = detailsEl.animate({
          height: [`${detailsEl.offsetHeight}px`, `${summary.offsetHeight + contentEl.offsetHeight}px`],
        }, {
          duration: Number(durationOnOpen),
          easing: easingOnOpen,
        });

        animation.onfinish = () => onOpenAnimationFinish(detailsEl, true);
      } else if (detailsEl.open) {
        animation = detailsEl.animate({
          height: [`${detailsEl.offsetHeight}px`, `${summary.offsetHeight}px`],
        }, {
          duration: Number(durationOnClose),
          easing: easingOnClose,
        });

        animation.onfinish = () => onAnimationFinish(detailsEl, false);
      }
    }
  }
};

const initAccordions = () => {
  document.addEventListener('click', e => {
    if (e.target.matches('summary') || e.target.closest('summary')) {
      e.preventDefault();
      toggleAccordion(e.target.matches('summary') ? e.target : e.target.closest('summary'));
    }
  });

  return false;
};

export default initAccordions();
export { toggleAccordion };
