import isEmail from "validator/lib/isEmail";

function validateRule(rule, value, fieldLabel) {
  switch (rule) {
    case "required":
      return !value ? `${fieldLabel} is a required field` : true;
    case "email":
      return !isEmail(value)
        ? `Please enter a valid email address`
        : true;
    default:
      return true;
  }
}

function validateForm(form) {
  const allErrors = [];
  form.querySelectorAll("[data-form-field]").forEach((el) => {
    if (!el.dataset.formFieldValidate) {
      return;
    }
    const type = el.dataset.formFieldType;
    const rules = el.dataset.formFieldValidate.split(":::");
    let value = null;
    let input;

    switch (type) {
      case "text":
      case "textarea":
      case "integer":
        input = el.querySelector("input");
        value = input ? input.value : null;
        break;
      case "select":
        input = el.querySelector("select");
        value = input ? input.value : null;
        break;
      case "toggle":
      case "radio":
      case "checkboxes":
        value = !!el.querySelector("input:checked");
        break;
      case "assets":
        input = el.querySelector("input");
        value = input ? input.files.length : null;
        break;
    }

    rules.forEach((rule) => {
      const result = validateRule(rule, value, el.dataset.formFieldLabel);
      if (result !== true) {
        allErrors.push(result);
        setFieldErrorMessage(form, el.dataset.formField, result);
      }
    });
  });

  return allErrors;
}

function setFieldErrorMessage(form, fieldHandle, message) {
  const field = form.querySelector(`[data-form-field=${fieldHandle}]`);
  if (field) {
    field.classList.add("has-error");
    const fieldErrorEl = field.querySelector(`[data-error-message]`);
    if (fieldErrorEl) {
      fieldErrorEl.innerHTML = message;
    }
  }
}

function setGlobalErrorMessage(form, message) {
  form.classList.add("has-errors");
  const errorsEl = form.querySelector("[data-all-error-messages]");
  if (errorsEl) {
    errorsEl.innerHTML = message;
  }
}

function clearErrorMessages(form) {
  form.classList.remove("has-errors");
  form.querySelectorAll("[data-form-field]").forEach((el) => {
    el.classList.remove("has-error");
  });
  form.querySelectorAll("[data-error-message]").forEach((el) => {
    el.innerHTML = "";
  });
}

function onSubmit(e) {
  const form = e.target;
  if (!form.matches(".form")) {
    return;
  }
  e.preventDefault();

  if (form.classList.contains("is-loading")) {
    return;
  }

  clearErrorMessages(form);
  const formData = new FormData(form);
  const validationResult = validateForm(form);
  if (validationResult.length) {
    setGlobalErrorMessage(form, validationResult.map(i => `<div>${i}</div>`).join(''));
    return;
  }

  form.classList.add("is-loading");
  fetch(form.action, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    body: formData,
  })
    .then((response) => {
      return new Promise((resolve) =>
        response.json().then((json) =>
          resolve({
            status: response.status,
            ok: response.ok,
            json,
          })
        )
      );
    })
    .then(({ json, status }) => {
      switch (status) {
        case 200:
        case 201:
          form.classList.add("is-submitted");
          form.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          const event = new CustomEvent('form:sent', {cancelable: true, bubbles: true});
          form.dispatchEvent(event);
          break;
        default:
          setGlobalErrorMessage(
            form,
            json.errors ? json.errors.join("<br/>") : ""
          );
          if (json.error) {
            for (const [key, value] of Object.entries(json.error)) {
              setFieldErrorMessage(form, key, value);
            }
          }
          break;
      }
    })
    .catch((error) => {
      console.error("Error: ", error);
      setGlobalErrorMessage(form, error);
    })
    .finally(() => {
      form.classList.remove("is-loading");
    });
}

window.addEventListener("submit", onSubmit);
