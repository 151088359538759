// ensure all the needed assets are loaded
import.meta.glob(['../images/**', '../fonts/**', '../icons/**']);

import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { gsap } from 'gsap';
import EvEmitter from 'ev-emitter';
import { loadAll, unloadAll } from './utils/sections/registration';
import { initScrollLinks, scrollToHash } from '@/utils/scroll-to-hash';
import '../vendor/statamic-ajax-forms/js/statamic-ajax-forms';
import 'wildlabs-frontend-core/js/utils';
import 'wildlabs-frontend-core/js/forms';
import './utils/accordion';
import 'wildlabs-frontend-core/js/tabs';

gsap.registerPlugin(ScrollToPlugin);

class App extends EvEmitter {
  constructor() {
    super();

    this.pageType = null;
    this.app = document.getElementById('main');

    this.registerLazySections().then(() => {
      this.initAccessibility();
      this.loadSections();
      this.onPageEnter();
    });
  }

  initAccessibility() {
    document.addEventListener('click', () => document.body.classList.remove('is-tab-pressed'));
    document.addEventListener('keyup', (e) => {
      e.keyCode === 9 && document.body.classList.add('is-tab-pressed');
    });
  }

  registerSections() {
    const sections = import.meta.glob('./sections/*.js');
    const promises = [];
    for (const path in sections) {
      promises.push(sections[path]());
    }

    return Promise.all(promises);
  }

  registerLazySections() {
    const promises = [];
    const SECTION_SELECTOR = '[data-section-component]';
    // const mandatorySections = ['header'];

    const sections = document.querySelectorAll(SECTION_SELECTOR);
    const sectionNames = [...sections].map((s) => s.dataset.sectionComponent).filter((name) => !!name);
    const sectionsSet = new Set(sectionNames);

    sectionsSet.forEach((sectionName) => {
      const sectionImport = import('./sections/' + sectionName + '.js');
      promises.push(sectionImport);
    });

    return Promise.all(promises);
  }

  onPageEnter() {
    initScrollLinks();
    scrollToHash(window.location.hash);

    const header = document.querySelector('#header');

    if(header) {
      header.classList.remove('is-loading');
    }

    ['is-loading', 'is-scrolled-up', 'is-scrolled-down', 'modal-is-open', 'is-locked'].forEach((className) =>
      document.body.classList.remove(className)
    );
  }

  loadSections(container = null) {
    loadAll(container || this.app);
  }

  unloadSections(container = null) {
    unloadAll(container || this.app);
  }
}

window.WL = window.WL || {};
window.WL.app = new App();
